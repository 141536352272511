import fetchPosts from "common/fetch/fetchPosts";
import fetchSeo from "common/fetch/fetchSeo";

import Layout from "components/common/Layout";
import HongKongHome from "components/templates/hk";

import dataStatic from "./data.json";

export default function Home({ data }) {
  return (
    <div>
      <Layout locationRouter="hk" page={data.page}>
        <HongKongHome posts={data?.posts} data={dataStatic} />
      </Layout>
    </div>
  );
}

export const getStaticProps = async () => {
  const locale = "hk";
  const uri = "/";
  const page = await fetchSeo({
    uri,
    locale,
  });

  const postPin = await fetchPosts({
    postPerPage: 1,
    featuredImage: "LARGE",
    gqlNode: `
    databaseId
    categories {
      nodes {
        name
        uri
        parent {
          node {
            id
          }
        }
      }
    }`,
    variables: [
      {
        name: "where",
        type: "RootQueryToPostConnectionWhereArgs",
        value: {
          in: [],
        },
      },
    ],
    locale,
  });

  const hasPinPost = Boolean(postPin?.nodes && postPin?.nodes.length > 0);

  const posts = await fetchPosts({
    postPerPage: hasPinPost ? 3 : 4,
    featuredImage: "LARGE",
    gqlNode: `
        date
        categories {
          nodes {
            name
            uri
            parent {
              node {
                id
              }
            }
          }
        }`,
    locale,
    variables: [
      {
        name: "where",
        type: "RootQueryToPostConnectionWhereArgs",
        value: {
          notIn: hasPinPost ? [postPin.nodes[0].databaseId] : [],
        },
      },
    ],
  });

  const dataPosts = () => {
    if (hasPinPost && posts?.nodes) {
      return postPin.nodes.concat(posts.nodes);
    }
    if (posts?.nodes) {
      return posts.nodes;
    }

    return [];
  };

  return {
    props: {
      data: {
        page: page || {},
        posts: dataPosts(),
      },
      revalidate: 1,
    },
  };
};
