import { useState } from "react";

import PropTypes from "prop-types";

import dataTrustpilot from "common/data/trustpilot/data.json";
import useResizeScreenModified from "common/hooks/useResizeScreenModified";

import Img from "components/atoms/Img";
import Link from "components/atoms/LinkRouter";
import Title from "components/atoms/Title";
import FormNewsLetter from "components/molecules/FormNewsletter";
import AboutUs from "components/organisms/AboutUs";
import Banner from "components/organisms/Banner";
import IndustryNewsInsights from "components/organisms/IndustryNewsInsights";
import OurPartners from "components/organisms/OurPartners";
import TrustpilotFeedBack from "components/organisms/TrustpilotFeedBack";

const HongKongHome = ({ posts, data }) => {
  const [companyName, setCompanyName] = useState("");

  const handleInput = (event) => {
    setCompanyName(event.target.value);
  };

  const handleSubmit = () => {
    window.location.href = `/onboarding/register?country_id=98&company_name=${companyName}`;
  };

  const [screen, setScreen] = useState("DESKTOP");

  useResizeScreenModified(setScreen);

  return (
    <>
      <section>
        {/* <BannerChristmas /> */}
        <Banner
          minHeightBanner="banner-min-h-600"
          title="Jump-start your<br/>Hong Kong business<br/>with ease"
          titleProps={{
            textColor: "bg-bbc-gradient-liner1 text-transparent bg-clip-text",
          }}
          description="Take a digital route to get your Hong Kong business off the ground from day one and free you from manual administration and compliance."
          bgColor="bg-bbc-gradient-11 bg-[length:180%_180%] animate-[gradientAnimation_10s_ease_infinite]"
          bgStyle={{ backgroundBlendMode: "overlay, normal, normal, normal" }}
          leftClassName="relative mt-4 lg:mt-0 col-12 lg:col-6 xl:col-7 z-40 "
          leftContent={
            <div className="mb-10 lg:mb-10">
              <div className="flex flex-col justify-between rounded-full py-1 pr-1 text-sm lg:h-[50px] lg:flex-row lg:bg-white">
                <div className="flex flex-grow items-center lg:px-4">
                  <input
                    id="ipt-company-name"
                    placeholder="Your company name"
                    className="mb-4 w-full truncate rounded-full bg-white px-4 py-2 outline-none lg:mb-0"
                    value={companyName}
                    onChange={handleInput}
                  />
                </div>
                <div className="lg:max-w-[237px]">
                  <button
                    onClick={() => handleSubmit()}
                    className="h-full w-full truncate rounded-full bg-bbc-primary py-2 px-6 text-base text-white lg:text-sm xl:text-base"
                  >
                    Register Your Company
                  </button>
                </div>
              </div>
            </div>
          }
          rightClassName="static hidden lg:block lg:col-6 xl:col-5"
          rightContent={
            <>
              {screen === "DESKTOP" && (
                <Img
                  className="w-full "
                  wrapper={{
                    className: "bbc-banner-image-bottom-0 banner-home",
                  }}
                  src="/assets/images/hk/home/banner-hk-home.png"
                  width={1034}
                  height={720}
                  alt="banner-home"
                  priority={true}
                />
              )}
            </>
          }
          wave={
            <div className="relative">
              <Img
                src="/assets/images/wave/exclude-wave.svg"
                className="h-full w-full"
                width={1920}
                height={95}
                wrapper={{
                  className: "w-full h-full",
                }}
                alt="banner-img"
              />
            </div>
          }
        ></Banner>
      </section>
      <IndustryNewsInsights data={posts} />
      <AboutUs
        data={data?.dataAboutUs}
        bgImage="/assets/images/common/pexels-gradient.png"
      />
      <OurPartners />
      <section className="relative pt-10 lg:mb-0 lg:bg-bbc-brand-100 lg:pt-36 lg:pb-8">
        <Img
          className="absolute -top-1 left-0 right-0 w-full"
          src="/assets/images/wave/curve_home-wave.svg"
          width={1920}
          height={158}
          wrapper={{ className: "hidden lg:block" }}
          alt="wave3"
        />
        <div className="container">
          <Title
            text="What we serve"
            className="mb-6 text-bbc-30px font-semibold capitalize text-bbc-brand-800 lg:text-bbc-40px"
          />
          <p className="leading-bbc-26 text-bbc-gray">
            We cover a wide range of digital corporate services to help your
            business get things done right from the start, and other supporting
            areas that keep it running smoothly during company operation.
          </p>
          <div className="row mt-6 items-center lg:mt-10">
            <div className="col-12 text-center lg:col-6">
              <Img
                src="/assets/images/common/corporate_secretary-hk_sg.png"
                width={540}
                height={540}
                alt="corporate-secretary"
              />
            </div>
            <div className="col-12 lg:col-5">
              <div>
                <Title
                  as="h3"
                  text="Corporate Secretary"
                  className="mb-4 text-2xl font-semibold leading-9 text-bbc-brand-800 lg:mb-6 lg:text-bbc-30px"
                />
                <p className="mb-6 leading-bbc-26">
                  We&apos;ll keep track of filing deadlines, relevant
                  administration and compliance procedures for your Hong Kong
                  company. Make time to grow your business, not your paperworks.
                </p>
                <div className="text-center lg:text-left">
                  <Link
                    href="/company-secretary"
                    className="inline-block cursor-pointer rounded-[50px] border border-bbc-brand-500 py-2 px-6 font-semibold leading-bbc-26 text-bbc-brand-500 hover:bg-bbc-brand-500 hover:text-white"
                  >
                    Explore
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-1 hidden lg:block" />
          </div>
          <div className="row mt-6 items-center lg:mt-10">
            <div className="col-1 hidden lg:block" />
            <div className="col-12 order-2 mt-6 lg:col-5 lg:order-0 lg:mt-0">
              <div>
                <Title
                  as="h3"
                  text="Company Formation"
                  className="mb-4 text-2xl font-semibold leading-9 text-bbc-brand-800 lg:mb-6 lg:text-bbc-30px"
                />
                <p className="mb-6 leading-bbc-26">
                  Enjoy your freedom and flexibility of starting business from
                  anywhere across the globe. We utilize digital onboarding
                  platforms and client portal to streamline every steps for your
                  great experience.
                </p>
                <div className="text-center lg:text-left">
                  <Link
                    href="/company-incorporation"
                    className="inline-block cursor-pointer rounded-[50px] border border-bbc-brand-500 py-2 px-6 font-semibold leading-bbc-26 text-bbc-brand-500 hover:bg-bbc-brand-500 hover:text-white"
                  >
                    Explore
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-12 text-center lg:col-6">
              <Img
                src="/assets/images/hk/home/company_formation-hk-home.png"
                width={540}
                height={540}
                alt="company-formation"
              />
            </div>
          </div>
          <div className="row mt-6 items-center lg:mt-10">
            <div className="col-12 text-center lg:col-6">
              <Img
                src="/assets/images/common/business_accounts-hk_sg.png"
                width={540}
                height={540}
                alt="business-accounts"
              />
            </div>
            <div className="col-12 lg:col-5">
              <div>
                <Title
                  as="h3"
                  text="Business Accounts"
                  className="mb-4 text-2xl font-semibold leading-9 text-bbc-brand-800 lg:mb-6 lg:text-bbc-30px"
                />
                <p className="mb-6 leading-bbc-26">
                  We offer consulting and banking introduction to both
                  traditional and alternative banking - so you can pick the
                  right one for your specific case and navigate through all
                  procedures in the application process.
                </p>
                <div className="text-center lg:text-left">
                  <Link
                    href="/open-bank-account"
                    className="inline-block cursor-pointer rounded-[50px] border border-bbc-brand-500 py-2 px-6 font-semibold leading-bbc-26 text-bbc-brand-500 hover:bg-bbc-brand-500 hover:text-white"
                  >
                    Explore
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-1 hidden lg:block" />
          </div>
          <div className="row mt-6 items-center lg:mt-10">
            <div className="col-1 hidden lg:block" />
            <div className="col-12 order-1 mt-6 lg:col-5 lg:order-0 lg:mt-0">
              <div>
                <Title
                  as="h3"
                  text="Accounting & Auditing"
                  className="mb-4 text-2xl font-semibold leading-9 text-bbc-brand-800 lg:mb-6 lg:text-bbc-30px"
                />
                <p className="mb-6 leading-bbc-26">
                  Our qualified accounting service saves money, time and
                  frustration for small business owners unfamiliar with
                  accounting practices.
                </p>
                <div className="text-center lg:text-left">
                  <Link
                    href="/accounting-and-auditing"
                    className="inline-block cursor-pointer rounded-[50px] border border-bbc-brand-500 py-2 px-6 font-semibold leading-bbc-26 text-bbc-brand-500 hover:bg-bbc-brand-500 hover:text-white"
                  >
                    Explore
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-12 text-center lg:col-6">
              <Img
                src="/assets/images/common/accounting_auditing-hk_sg.png"
                width={540}
                height={479}
                alt="accounting-auditing"
              />
            </div>
          </div>
        </div>
      </section>
      <div className="mb-20 hidden lg:block">
        <Img
          className="w-full"
          src="/assets/images/wave/curve_home1-wave.svg.svg"
          width={1920}
          height={116}
          wrapper={{ className: "hidden lg:block" }}
          alt="wave4"
        />
      </div>
      <section>
        <div className="container">
          <Title className="title-h2 mb-6 pb-0 text-center text-bbc-brand-800">
            Take it from the people we&lsquo;ve helped
          </Title>
          <p className="text-center">
            Clients talked about us on{" "}
            <a
              className="font-medium text-bbc-primary hover:text-bbc-secondary-300"
              target="_blank"
              rel="noreferrer"
              href="https://www.trustpilot.com/review/bbcincorp.com"
            >
              Trustpilot
            </a>
          </p>
          <TrustpilotFeedBack data={dataTrustpilot.feedback} />
        </div>
      </section>

      <FormNewsLetter bgColor="bg-bbc-gradient-9" />
    </>
  );
};

export default HongKongHome;

HongKongHome.propTypes = {
  data: PropTypes.object,
  posts: PropTypes.array,
};

HongKongHome.defaultProps = {
  data: {},
  posts: [],
};
